"use client";
import Image from "next/image";
import { IMAGE_TYPE } from "@/src/const/const";
import { memo, useState } from "react";
import styles from "./item-dentistry.module.scss";
import ImageCommon from "@/src/component/image/image";
import iconLocation from "@/public/icon/icon-location-circle.svg";
import btnPromotion from "@/public/image/btn-promotion-v2.webp";
import iconStar from "@/public/icon/icon-start-3d.svg";
import { convertToMillionRange, wrapperRouterPush } from "@/src/util/util";
import useWindowSize from "@/src/hooks/useWindowSize";
import { Col, Modal, Row } from "antd";
import ChatBox from "@/src/component/detail/chatbox";
import iconCloseModal from "@/public/icon/icon-close.png";
import iconRight from "@/public/icon/icon-right-blueblack.svg";
import { useRouter } from "next/navigation";
import FormChatbotMobile from "@/src/component/dialog/form-chat-bot-mobile";

export default function ItemDentistry({ data }) {
  const [openModal, setOpenModal] = useState(false);
  const [dataModal, setDataModal] = useState(null);
  const [openModalChatbotMobile, setOpenModalChatbotMobile] = useState(false);
  const [clinicDetail, setClinicDetail] = useState(null);

  const router = useRouter();
  const date = new Date();
  const validContract = data?.user?.user_agreements?.length > 0;
  const logo = data?.image?.find(
    (item) => item.image_type == IMAGE_TYPE.avatar
  );
  const onShowDetail = (slug) => {
    window.open(`/phong-kham/${slug}`);
  };
  return (
    <div className={styles.wpResult}>
      <div className={styles.itemResult}>
        <div className={styles.wpInfo} onClick={() => onShowDetail(data.slug)}>
          <div className={styles.wpFirstLine}>
            <div className={styles.wpContainerAbove}>
              <div className={styles.wpLeft}>
                <ImageCommon data={logo} style={styles.logo} />
              </div>
              <div className={styles.wpRight}>
                <div className={styles.titleClinic}>
                  {data?.name}
                  <div
                    className={`${styles.buttonAdvise} button-blue`}
                    onClick={(e) => {
                      setDataModal(data);
                      setOpenModal(true);
                      e.stopPropagation();
                    }}
                  >
                    Đặt lịch giữ chỗ
                  </div>
                </div>
                {data?.address?.length > 0 && (
                  <div className={styles.firstLineTag}>
                    <div className={styles.wpTagInfo}>
                      <Image
                        src={iconLocation}
                        className={styles.iconLocation}
                        alt="Location icon"
                      />
                      {data.address[0]}
                    </div>
                    <div
                      className={`${styles.buttonAdvise} ${styles.buttonAdviseSecond} button-red`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenModalChatbotMobile(true);
                        setClinicDetail(data);
                      }}
                    >
                      Nhận tư vấn
                    </div>
                  </div>
                )}
                <div className={styles.wpRatingLine}>
                  {data?.rating_overview?.overall_average ? (
                    <div className={styles.wpRatingInLine}>
                      <div className={styles.ratingPoint}>
                        <div className={styles.point}>
                          {data?.rating_overview?.overall_average.toFixed(1)}
                        </div>
                        <Image
                          className={styles.iconRatingStar}
                          src={iconStar}
                          alt="icon"
                        />
                      </div>
                      <div className={styles.dash}></div>
                    </div>
                  ) : null}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className={styles.ratingQuantity}>
                      {data?.rating_overview?.number_of_ratings} Đánh giá
                    </div>
                    <div className={styles.dash}></div>
                    <div className={styles.numberOfConsultation}>
                      {data?.total_contact ? data?.total_contact : 0} Người đặt
                      lịch
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {data.service.length > 0 && <PriceService item={data} />}
            <div className={styles.thirdLineTag}>
              <div className={`${styles.buttonDetail} button-white`}>
                Xem chi tiết
              </div>
              <div
                className={`${styles.buttonAdvise} button-blue`}
                onClick={(e) => {
                  e.stopPropagation();
                  setDataModal(data);
                  setOpenModal(true);
                }}
              >
                Đặt lịch giữ chỗ
              </div>
              {/* {validContract && (
                        <Image
                          src={btnPromotion}
                          // onClick={(e) => {
                          //   e.stopPropagation();
                          //   setOpenCouponModal(true);
                          // }}
                          className={styles.couponButton}
                          alt="promotion button"
                        />
                      )} */}
              <div
                className={`${styles.buttonAdvise} ${styles.buttonAdviseSecond} button-red`}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenModalChatbotMobile(true);
                  setClinicDetail(data);
                }}
              >
                Nhận tư vấn
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="wpModalChatBox"
        open={openModal}
        footer={null}
        closable={true}
        width={380}
        style={{ top: 10 }}
        destroyOnClose={true}
        centered
        onCancel={() => {
          setOpenModal(false);
        }}
      >
        <div style={{ position: "relative" }}>
          <ChatBox detailClinic={dataModal} isModal={true} />
        </div>
      </Modal>
      <Modal
        className="modal-chatbot-mobile"
        open={openModalChatbotMobile}
        onCancel={() => {
          setOpenModalChatbotMobile(false);
        }}
        footer={null}
        closable={true}
        width={380}
        destroyOnClose={true}
        centered
      >
        <FormChatbotMobile clinic={clinicDetail} />
      </Modal>
    </div>
  );
}

const PriceService = memo((props) => {
  const { width } = useWindowSize();
  const prioritizedServices = [
    "Niềng răng",
    "Trồng răng Implant",
    "Bọc răng sứ",
    "Nhổ răng khôn",
    "Răng giả tháo lắp",
    "Trám răng",
  ];
  let newArrPriority = [];
  let newArrNotPriority = [];
  props.item.service.forEach((curr) => {
    if (curr.service.length > 0) {
      const serviceName = curr.category_service.name;
      const priorityIndex = prioritizedServices.indexOf(serviceName);
      if (priorityIndex !== -1) {
        newArrPriority[priorityIndex] = curr;
      } else {
        newArrNotPriority.push(curr);
      }
    }
  });
  const arrService = [...newArrPriority.filter(Boolean), ...newArrNotPriority];
  if (arrService.length > 0) {
    return (
      <div key={props.item.id}>
        <div className={styles.listPrice}>
          <Row className={`${styles.wpList} wp-list-price`}>
            {arrService.slice(0, 6).map((serviceValue, index) => {
              const hideDashPrice = (() => {
                return width >= 992
                  ? index === 2 || index === 5
                  : width >= 576
                  ? index % 2 === 1
                  : true;
              })();
              const minAmount = Math.min(
                ...serviceValue.service.map((service) => service.amount)
              );
              const maxAmountMax = Math.max(
                ...serviceValue.service.map((service) => service.amount_max)
              );
              return (
                <Col
                  xs={24}
                  sm={12}
                  lg={8}
                  className={styles.priceItem}
                  key={index}
                >
                  <div className={styles.firstColumn}>
                    <div className={styles.priceItemName}>
                      {serviceValue.category_service.name}
                    </div>
                  </div>
                  <div className={styles.secondColumn}>
                    <div className={styles.priceItemAmount}>
                      {convertToMillionRange(minAmount, maxAmountMax)}
                    </div>
                  </div>
                  {!hideDashPrice && <div className={styles.dashPrice} />}
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    );
  }
});
